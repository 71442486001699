import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const statusMap = {
    755730000: { text: 'Draft Agenda', icon: EditOutlinedIcon },
    755730001: { text: 'Approved', icon: CheckIcon },
    755730002: { text: 'Rejected', icon: DoDisturbIcon },
    755730003: { text: 'Pending', icon: AccessTimeIcon }
};

export default statusMap;
