import { useEffect, useState } from 'react';
import axios from 'utils/axios';

// MUI imports
import { Grid } from '@mui/material';

// Project imports
import MainCard from 'ui-component/cards/MainCard';
import AssessmentsList from './AssessmentsList';
import ErrorSnackbarAlert from 'utils/ErrorSnackbarAlert';

export default function AssessmentsPage() {
    const [loadingAssessments, setLoadingAssessments] = useState(false);
    const [assessments, setAssessments] = useState([]);
    const [failureMessage, setFailureMessage] = useState('');

    useEffect(() => {
        async function getAssessments() {
            setLoadingAssessments(true);
            try {
                const { data } = await axios.get(`/assessments/requiredassessments?contactId=${localStorage.id}`);
                setAssessments(data);
            } catch (ex) {
                console.log(ex);
                setFailureMessage(ex);
            }
            setLoadingAssessments(false);
        }

        getAssessments();
    }, []);

    return (
        <>
            <MainCard title={`Assessments ${assessments[0] ? ` - Term ${assessments[0].term}` : ''}`}>
                <Grid container spacing={6} sx={{ padding: 2 }}>
                    <Grid item xs={6}>
                        <AssessmentsList
                            assessments={assessments.filter((a) => !a.completed)}
                            loading={loadingAssessments}
                            term={assessments[0] ? assessments[0].term : ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AssessmentsList assessments={assessments.filter((a) => a.completed)} completed loading={loadingAssessments} />
                    </Grid>
                </Grid>
            </MainCard>
            {failureMessage && <ErrorSnackbarAlert message={failureMessage} setMessage={setFailureMessage} />}
        </>
    );
}
