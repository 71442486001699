import axios from 'utils/axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import {
    Alert,
    Autocomplete,
    Backdrop,
    Button,
    Checkbox,
    Box,
    CircularProgress,
    Collapse,
    Container,
    FormControlLabel,
    FormGroup,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
    IconButton,
    FormLabel
} from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import TakeAttendanceForm from './TakeAttendanceForm';
import MainCard from 'ui-component/cards/MainCard';
import GradeStudentsForm from './gradeStudentsForm';
import { IconLock } from '@tabler/icons';
import { openSnackbar } from 'store/slices/snackbar';
import TutoringSession from './tutoringSession';
import { useDispatch } from 'store';
import TeacherAbsentSection from './TeacherAbsentSection';
import { showErrorAlert, parseErrorResponse } from 'utils/global-functions';
import { startOfWeek } from 'date-fns';

const StandardSession = ({ session, setSession, closeSession, loadingSession, setLoadingSession }) => {
    const [tab, setTab] = useState('plan');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [plan, setPlan] = useState(null);
    const [isSavingSessionPlan, setIsSavingSessionPlan] = useState(false);
    const [isSavingTeacherAttendance, setIsSavingTeacherAttendance] = useState(false);
    const loading = open && options.length === 0;
    const [attendanceIncomplete, setAttendanceIncomplete] = useState(false);
    const [attendanceCompleted, setAttendanceCompleted] = useState(false);
    const [performanceCompleted, setPerformanceCompleted] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [teacherAbsent, setTeacherAbsent] = useState(false);
    const [sessionConfirmed, setSessionConfirmed] = useState(false);
    const [isFutureSession, setIsFutureSession] = useState(false);
    const [prepTimeCompleted, setPrepTimeCompleted] = useState(false);
    const [lessonPlanChanged, setLessonPlanChanged] = useState(false);
    const [lessonPlanChangedLocally, setLessonPlanChangedLocally] = useState(false);
    const [hasStudents, setHasStudents] = useState(false);
    const [isSavingAttendance, setIsSavingAttendance] = useState(false);
    const [substituteTeachers, setSubstituteTeachers] = useState([]);
    const [teacherAbsentSaveIsDirty, setTeacherAbsentSaveIsDirty] = useState(false);
    const [closeSessionDialogOpen, setCloseSessionDialogOpen] = useState(false);
    const [isPastBillingDeadline, setIsPastBillingDeadline] = useState(false);

    const dispatch = useDispatch();
    const { state } = useLocation();

    const handlePlanChange = (event, newValue) => {
        if (!newValue) {
            setPlan('');
        } else {
            setPlan({ ...newValue });
        }
        setLessonPlanChangedLocally(true);
    };

    const updatePrepTimeCompleted = async (value) => {
        setIsSavingTeacherAttendance(true);
        try {
            const { data } = await axios.post('/Session/SubmitPrepTimeCompleted', null, {
                params: { sessionId: session.id, completed: value }
            });

            setSession(data);
            setPrepTimeCompleted(value);
            setIsSavingTeacherAttendance(false);
        } catch (err) {
            console.error(err);
            showErrorAlert(err);
        }
    };
    const handlePrepSwitchChanged = () => {
        const prepTimeCompleted = !session.prepTimeCompleted;
        setSession({ ...session, prepTimeCompleted });
        updatePrepTimeCompleted(prepTimeCompleted);
    };
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    async function getSubstituteTeachers() {
        try {
            const { data } = await axios.get('/Session/SubstituteTeachers');
            const teachers = data.map((t) => ({ label: t.name, id: t.id }));
            setSubstituteTeachers(teachers);
        } catch (err) {
            console.error(err);
            showErrorAlert(dispatch, err);
        }
    }

    useEffect(() => {
        async function getSession() {
            setHasStudents(session === '' || session.students.length !== 0);
            const startDate = new Date(session.start);
            let pastBillingDeadline = false;
            const today = new Date();
            if (localStorage.getItem('isSupervisor') === 'true') {
                today.setSeconds(0);
                const lastDateToSubmit = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 8, 23, 59, 0);
                pastBillingDeadline = today > lastDateToSubmit;
            } else if (!localStorage.roles?.includes('Admin')) {
                pastBillingDeadline = startDate < startOfWeek(new Date(), { weekStartsOn: 2 });
            }
            setIsPastBillingDeadline(pastBillingDeadline);
            setIsFutureSession(
                new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) >
                    new Date(today.getFullYear(), today.getMonth(), today.getDate())
            );
            setPlan(session.lessonPlan);

            if (session.students.every((student) => student.attendance)) {
                setAttendanceCompleted(true);
            }

            // If all students have either a score, or are absent, then performance is complete.
            if (session.students.every((s) => s.score != null || s.attendance?.attendance === 1)) {
                setPerformanceCompleted(true);
            }

            setTeacherAbsent(session.teacherAbsent === 1);
            setPrepTimeCompleted(session.prepTimeCompleted);
            setSessionConfirmed(session.supervisorConfirmed);

            await getSubstituteTeachers();

            setLoadingSession(false);
        }

        getSession();
    }, []);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const { data } = await axios.get(`/lessonlibrary/list/${session.id}`);
            if (active) {
                setOptions(data.filter((l) => !l.hasCoachingSessions || l.id === plan.id));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const onSubmitAttendance = async (students) => {
        if (students.some((s) => s.attendance === 0)) {
            setAttendanceIncomplete(true);
            return;
        }

        setAttendanceIncomplete(false);
        setAttendanceCompleted(true);

        try {
            const { data } = await axios.post('/Session/SubmitAttendance', { Students: students, SessionId: session.id });

            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Attendance submitted successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );

            setSession(data);
            setTab('performance');
        } catch (err) {
            console.error(err);
            dispatch(
                openSnackbar({
                    open: true,
                    message: parseErrorResponse(err),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    };

    const onCancel = () => {
        if (teacherAbsentSaveIsDirty) {
            setCloseSessionDialogOpen(true);
        } else {
            closeSession();
        }
    };

    const onSavePlan = async () => {
        try {
            setIsSavingSessionPlan(true);
            await axios.post('/Session/SubmitlessonPlan', { sessionId: session.id, lessonPlanId: plan.id }).then(console.log('saved!'));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Lesson plan submitted successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            setLessonPlanChanged(true);
        } catch (err) {
            console.error(err);
            dispatch(
                openSnackbar({
                    open: true,
                    message: parseErrorResponse(err),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        } finally {
            setIsSavingSessionPlan(false);
        }
    };

    const onSubmitScores = async (studentScores) => {
        try {
            await axios.post('/Session/SubmitScores', { sessionId: session.id, scores: studentScores }).then(console.log('saved!'));
            setPerformanceCompleted(true);
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Performance submitted successfully!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            closeSession();
        } catch (err) {
            console.error(err);
            dispatch(
                openSnackbar({
                    open: true,
                    message: parseErrorResponse(err),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#975fff'
            },
            secondary: {
                main: '#FF7276'
            }
        }
    });

    const teacherAbsentWithoutSubstitute = () => session.teacherAbsent && !session.substituteTeacherId;
    const disableSession = () => teacherAbsentWithoutSubstitute() || session.naSession;

    return (
        <MainCard sx={{ position: 'relative' }}>
            {session?.schoolClass?.title1TeacherName && (
                <Typography variant="h4" align="center">
                    {`${state.session.name} - ${session.schoolClass.title1TeacherName}`}
                </Typography>
            )}

            <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <Tooltip title="Close">
                    <IconButton onClick={onCancel}>
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <FormGroup>
                {isPastBillingDeadline && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="error" sx={{ fontWeight: 'bold', mb: 2, mt: 2 }}>
                            This session has passed the billing deadline.
                        </Alert>
                    </ThemeProvider>
                )}
                {session !== null && !hasStudents && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="info" color="primary" sx={{ mb: 2, mt: 2 }}>
                            No students effective on session date
                        </Alert>
                    </ThemeProvider>
                )}

                {session !== null && session.naSession && isFutureSession && hasStudents && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="info" color="primary" sx={{ mb: 2, mt: 2 }}>
                            Attendance cannot be submitted for NA or future sessions
                        </Alert>
                    </ThemeProvider>
                )}
                {session !== null && session.naSession && !isFutureSession && hasStudents && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="info" color="primary" sx={{ mb: 2, mt: 2 }}>
                            Attendance cannot be submitted for NA sessions
                        </Alert>
                    </ThemeProvider>
                )}
                {session !== null && substituteTeachers.length > 0 && !session.naSession && hasStudents && (
                    <TeacherAbsentSection
                        substituteTeachers={substituteTeachers}
                        initialTeacherAbsent={session.teacherAbsent === 1}
                        teacherAbsent={teacherAbsent}
                        setTeacherAbsent={setTeacherAbsent}
                        disabled={sessionConfirmed || isPastBillingDeadline}
                        substituteTeacherId={session.substituteTeacherId}
                        attendanceTaken={session.attendanceTaken}
                        isSavingTeacherAttendance={isSavingTeacherAttendance}
                        setIsSavingTeacherAttendance={setIsSavingTeacherAttendance}
                        sessionId={session.id}
                        setTab={setTab}
                        setSession={setSession}
                        teacherAbsentSaveIsDirty={teacherAbsentSaveIsDirty}
                        setTeacherAbsentSaveIsDirty={setTeacherAbsentSaveIsDirty}
                    />
                )}
                {session !== null && !session.naSession && isFutureSession && hasStudents && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="info" color="primary" sx={{ mb: 2, mt: 2 }}>
                            Attendance cannot be submitted for future sessions
                        </Alert>
                    </ThemeProvider>
                )}
                {sessionConfirmed && hasStudents && (
                    <ThemeProvider theme={theme}>
                        <Alert severity="info" color="secondary" sx={{ mb: 2, mt: 2 }}>
                            Cannot edit a session confirmed by supervisor
                        </Alert>
                    </ThemeProvider>
                )}

                <Dialog open={closeSessionDialogOpen}>
                    <DialogTitle>
                        Are you sure you want to close the session?
                        <Typography>You have unsaved changes.</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeSession}>Yes</Button>
                        <Button onClick={() => setCloseSessionDialogOpen(false)}>No</Button>
                    </DialogActions>
                </Dialog>
            </FormGroup>

            {(loadingSession || isSavingTeacherAttendance || isSavingAttendance) && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                    <CircularProgress color="primary" />
                </Backdrop>
            )}
            {/* Instructional session */}
            {session !== null && session.instructionalMode === 'Instructional' && hasStudents && (
                <TabContext value={tab}>
                    <Tabs
                        disabled={disableSession()}
                        value={teacherAbsent ? false || session.naSession : tab}
                        variant="fullWidth"
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab
                            disabled={disableSession()}
                            value="plan"
                            label={
                                <Box display="flex" alignItems="center">
                                    PLAN
                                    {(session.lessonPlan || lessonPlanChanged) && (
                                        <CheckCircleOutline
                                            sx={{
                                                marginLeft: 1,
                                                verticalAlign: 'middle',
                                                fontSize: '1.2rem'
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                        />
                        <Tab
                            disabled={disableSession()}
                            value="attendance"
                            label={
                                <Box display="flex" alignItems="center">
                                    ATTENDANCE
                                    {attendanceCompleted && (
                                        <CheckCircleOutline
                                            sx={{
                                                marginLeft: 1,
                                                verticalAlign: 'middle',
                                                fontSize: '1.2rem'
                                            }}
                                        />
                                    )}
                                </Box>
                            }
                        />
                        <Tab
                            style={{ pointerEvents: 'auto' }}
                            icon={session && session.attendanceTaken === true ? null : <IconLock />}
                            iconPosition="start"
                            value="performance"
                            label={
                                <Tooltip
                                    title={
                                        session && session.attendanceTaken === true
                                            ? ''
                                            : 'You must take attendance before grading the students'
                                    }
                                >
                                    <Box display="flex" alignItems="center">
                                        <span>PERFORMANCE</span>
                                        {performanceCompleted && (
                                            <CheckCircleOutline
                                                sx={{
                                                    marginLeft: 1,
                                                    verticalAlign: 'middle',
                                                    fontSize: '1.2rem'
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Tooltip>
                            }
                            disabled={(session && !session.attendanceTaken) || disableSession()}
                        />
                    </Tabs>

                    <TabPanel value="plan" index={0}>
                        <>
                            <FormControlLabel
                                disabled={teacherAbsentWithoutSubstitute() || sessionConfirmed}
                                sx={{ mb: 2 }}
                                control={<Checkbox required checked={prepTimeCompleted} onChange={handlePrepSwitchChanged} />}
                                label="I did 20 minutes of prep"
                            />
                            <Autocomplete
                                disabled={disableSession() || sessionConfirmed}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onChange={handlePlanChange}
                                value={plan}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name || ''}
                                options={options}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Lesson plan"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            )
                                        }}
                                    />
                                )}
                            />
                            <Box textAlign="center" sx={{ mt: 2 }}>
                                <LoadingButton
                                    disabled={
                                        disableSession() ||
                                        (session.lessonPlan !== null && !lessonPlanChanged && !lessonPlanChangedLocally) ||
                                        plan === '' ||
                                        plan == null
                                    }
                                    variant="contained"
                                    loading={isSavingSessionPlan}
                                    loadingPosition="start"
                                    startIcon={<DoneAllIcon />}
                                    onClick={onSavePlan}
                                >
                                    Save Lesson Plan
                                </LoadingButton>
                            </Box>
                        </>
                    </TabPanel>
                    <TabPanel value="attendance" index={1}>
                        <>
                            <Collapse in={attendanceIncomplete}>
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    Please enter attendance for all students before submitting.
                                </Alert>
                            </Collapse>
                            {session === null ? (
                                <div />
                            ) : (
                                <TakeAttendanceForm
                                    event={session}
                                    onSubmit={onSubmitAttendance}
                                    isFutureSession={isFutureSession}
                                    isConfirmedSession={sessionConfirmed}
                                    disableFields={isPastBillingDeadline}
                                    isSavingAttendance={isSavingAttendance}
                                    setIsSavingAttendance={setIsSavingAttendance}
                                />
                            )}
                        </>
                    </TabPanel>
                    <TabPanel value="performance" index={2}>
                        {session === null ? <div /> : <GradeStudentsForm session={session} onSubmit={onSubmitScores} />}
                    </TabPanel>
                </TabContext>
            )}

            {/* Tutoring session */}
            {session !== null && session.instructionalMode === 'Tutoring' && hasStudents && (
                <TutoringSession
                    session={session}
                    disabled={disableSession() || isFutureSession || sessionConfirmed}
                    disableFields={isPastBillingDeadline}
                    isSubmittingSession={isSavingAttendance}
                    setIsSubmittingSession={setIsSavingAttendance}
                />
            )}
        </MainCard>
    );
};

export default StandardSession;
